var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import RcSwitch from 'rc-switch';
import classNames from 'classnames';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import Wave from '../_util/wave';
import { ConfigContext } from '../config-provider';
import SizeContext from '../config-provider/SizeContext';
import warning from '../_util/warning';
const Switch = React.forwardRef((_a, ref) => {
    var { prefixCls: customizePrefixCls, size: customizeSize, loading, className = '', disabled } = _a, props = __rest(_a, ["prefixCls", "size", "loading", "className", "disabled"]);
    warning('checked' in props || !('value' in props), 'Switch', '`value` is not a valid prop, do you mean `checked`?');
    const { getPrefixCls, direction } = React.useContext(ConfigContext);
    const size = React.useContext(SizeContext);
    const prefixCls = getPrefixCls('switch', customizePrefixCls);
    const loadingIcon = (<div className={`${prefixCls}-handle`}>
        {loading && <LoadingOutlined className={`${prefixCls}-loading-icon`}/>}
      </div>);
    const classes = classNames({
        [`${prefixCls}-small`]: (customizeSize || size) === 'small',
        [`${prefixCls}-loading`]: loading,
        [`${prefixCls}-rtl`]: direction === 'rtl',
    }, className);
    return (<Wave insertExtraNode>
        <RcSwitch {...props} prefixCls={prefixCls} className={classes} disabled={disabled || loading} ref={ref} loadingIcon={loadingIcon}/>
      </Wave>);
});
Switch.__ANT_SWITCH = true;
Switch.displayName = 'Switch';
export default Switch;
