var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import RcTreeSelect, { TreeNode, SHOW_ALL, SHOW_PARENT, SHOW_CHILD } from 'rc-tree-select';
import classNames from 'classnames';
import omit from 'rc-util/lib/omit';
import { useContext } from 'react';
import { ConfigContext } from '../config-provider';
import warning from '../_util/warning';
import getIcons from '../select/utils/iconUtil';
import renderSwitcherIcon from '../tree/utils/iconUtil';
import SizeContext from '../config-provider/SizeContext';
import { getTransitionName, getTransitionDirection } from '../_util/motion';
import { FormItemInputContext } from '../form/context';
import { getMergedStatus, getStatusClassNames } from '../_util/statusUtils';
const InternalTreeSelect = (_a, ref) => {
    var { prefixCls: customizePrefixCls, size: customizeSize, bordered = true, className, treeCheckable, multiple, listHeight = 256, listItemHeight = 26, placement, notFoundContent, switcherIcon, treeLine, getPopupContainer, dropdownClassName, treeIcon = false, transitionName, choiceTransitionName = '', status: customStatus, showArrow } = _a, props = __rest(_a, ["prefixCls", "size", "bordered", "className", "treeCheckable", "multiple", "listHeight", "listItemHeight", "placement", "notFoundContent", "switcherIcon", "treeLine", "getPopupContainer", "dropdownClassName", "treeIcon", "transitionName", "choiceTransitionName", "status", "showArrow"]);
    const { getPopupContainer: getContextPopupContainer, getPrefixCls, renderEmpty, direction, virtual, dropdownMatchSelectWidth, } = React.useContext(ConfigContext);
    const size = React.useContext(SizeContext);
    warning(multiple !== false || !treeCheckable, 'TreeSelect', '`multiple` will always be `true` when `treeCheckable` is true');
    const prefixCls = getPrefixCls('select', customizePrefixCls);
    const treePrefixCls = getPrefixCls('select-tree', customizePrefixCls);
    const treeSelectPrefixCls = getPrefixCls('tree-select', customizePrefixCls);
    const mergedDropdownClassName = classNames(dropdownClassName, `${treeSelectPrefixCls}-dropdown`, {
        [`${treeSelectPrefixCls}-dropdown-rtl`]: direction === 'rtl',
    });
    const isMultiple = !!(treeCheckable || multiple);
    const mergedShowArrow = showArrow !== undefined ? showArrow : props.loading || !isMultiple;
    // ===================== Form =====================
    const { status: contextStatus, hasFeedback, isFormItemInput, feedbackIcon, } = useContext(FormItemInputContext);
    const mergedStatus = getMergedStatus(contextStatus, customStatus);
    // ===================== Icons =====================
    const { suffixIcon, removeIcon, clearIcon } = getIcons(Object.assign(Object.assign({}, props), { multiple: isMultiple, showArrow: mergedShowArrow, hasFeedback,
        feedbackIcon,
        prefixCls }));
    // ===================== Empty =====================
    let mergedNotFound;
    if (notFoundContent !== undefined) {
        mergedNotFound = notFoundContent;
    }
    else {
        mergedNotFound = renderEmpty('Select');
    }
    // ==================== Render =====================
    const selectProps = omit(props, [
        'suffixIcon',
        'itemIcon',
        'removeIcon',
        'clearIcon',
        'switcherIcon',
    ]);
    // ===================== Placement =====================
    const getPlacement = () => {
        if (placement !== undefined) {
            return placement;
        }
        return direction === 'rtl'
            ? 'bottomRight'
            : 'bottomLeft';
    };
    const mergedSize = customizeSize || size;
    const mergedClassName = classNames(!customizePrefixCls && treeSelectPrefixCls, {
        [`${prefixCls}-lg`]: mergedSize === 'large',
        [`${prefixCls}-sm`]: mergedSize === 'small',
        [`${prefixCls}-rtl`]: direction === 'rtl',
        [`${prefixCls}-borderless`]: !bordered,
        [`${prefixCls}-in-form-item`]: isFormItemInput,
    }, getStatusClassNames(prefixCls, mergedStatus, hasFeedback), className);
    const rootPrefixCls = getPrefixCls();
    return (<RcTreeSelect virtual={virtual} dropdownMatchSelectWidth={dropdownMatchSelectWidth} {...selectProps} ref={ref} prefixCls={prefixCls} className={mergedClassName} listHeight={listHeight} listItemHeight={listItemHeight} treeCheckable={treeCheckable ? <span className={`${prefixCls}-tree-checkbox-inner`}/> : treeCheckable} treeLine={!!treeLine} inputIcon={suffixIcon} multiple={multiple} placement={getPlacement()} removeIcon={removeIcon} clearIcon={clearIcon} switcherIcon={(nodeProps) => renderSwitcherIcon(treePrefixCls, switcherIcon, treeLine, nodeProps)} showTreeIcon={treeIcon} notFoundContent={mergedNotFound} getPopupContainer={getPopupContainer || getContextPopupContainer} treeMotion={null} dropdownClassName={mergedDropdownClassName} choiceTransitionName={getTransitionName(rootPrefixCls, '', choiceTransitionName)} transitionName={getTransitionName(rootPrefixCls, getTransitionDirection(placement), transitionName)} showArrow={hasFeedback || showArrow}/>);
};
const TreeSelectRef = React.forwardRef(InternalTreeSelect);
const TreeSelect = TreeSelectRef;
TreeSelect.TreeNode = TreeNode;
TreeSelect.SHOW_ALL = SHOW_ALL;
TreeSelect.SHOW_PARENT = SHOW_PARENT;
TreeSelect.SHOW_CHILD = SHOW_CHILD;
export { TreeNode };
export default TreeSelect;
