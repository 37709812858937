var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import classNames from 'classnames';
import omit from 'rc-util/lib/omit';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import CheckOutlined from '@ant-design/icons/CheckOutlined';
import CheckCircleFilled from '@ant-design/icons/CheckCircleFilled';
import CloseCircleFilled from '@ant-design/icons/CloseCircleFilled';
import { ConfigConsumer } from '../config-provider';
import { tuple } from '../_util/type';
import warning from '../_util/warning';
import Line from './Line';
import Circle from './Circle';
import Steps from './Steps';
import { validProgress, getSuccessPercent } from './utils';
const ProgressTypes = tuple('line', 'circle', 'dashboard');
const ProgressStatuses = tuple('normal', 'exception', 'active', 'success');
export default class Progress extends React.Component {
    constructor() {
        super(...arguments);
        this.renderProgress = ({ getPrefixCls, direction }) => {
            const { props } = this;
            const { prefixCls: customizePrefixCls, className, size, type, steps, showInfo, strokeColor } = props, restProps = __rest(props, ["prefixCls", "className", "size", "type", "steps", "showInfo", "strokeColor"]);
            const prefixCls = getPrefixCls('progress', customizePrefixCls);
            const progressStatus = this.getProgressStatus();
            const progressInfo = this.renderProcessInfo(prefixCls, progressStatus);
            warning(!('successPercent' in props), 'Progress', '`successPercent` is deprecated. Please use `success.percent` instead.');
            let progress;
            // Render progress shape
            if (type === 'line') {
                progress = steps ? (<Steps {...this.props} strokeColor={typeof strokeColor === 'string' ? strokeColor : undefined} prefixCls={prefixCls} steps={steps}>
          {progressInfo}
        </Steps>) : (<Line {...this.props} prefixCls={prefixCls} direction={direction}>
          {progressInfo}
        </Line>);
            }
            else if (type === 'circle' || type === 'dashboard') {
                progress = (<Circle {...this.props} prefixCls={prefixCls} progressStatus={progressStatus}>
          {progressInfo}
        </Circle>);
            }
            const classString = classNames(prefixCls, {
                [`${prefixCls}-${(type === 'dashboard' && 'circle') || (steps && 'steps') || type}`]: true,
                [`${prefixCls}-status-${progressStatus}`]: true,
                [`${prefixCls}-show-info`]: showInfo,
                [`${prefixCls}-${size}`]: size,
                [`${prefixCls}-rtl`]: direction === 'rtl',
            }, className);
            return (<div {...omit(restProps, [
                'status',
                'format',
                'trailColor',
                'strokeWidth',
                'width',
                'gapDegree',
                'gapPosition',
                'strokeLinecap',
                'percent',
                'success',
                'successPercent',
            ])} className={classString}>
        {progress}
      </div>);
        };
    }
    getPercentNumber() {
        const { percent = 0 } = this.props;
        const successPercent = getSuccessPercent(this.props);
        return parseInt(successPercent !== undefined ? successPercent.toString() : percent.toString(), 10);
    }
    getProgressStatus() {
        const { status } = this.props;
        if (ProgressStatuses.indexOf(status) < 0 && this.getPercentNumber() >= 100) {
            return 'success';
        }
        return status || 'normal';
    }
    renderProcessInfo(prefixCls, progressStatus) {
        const { showInfo, format, type, percent } = this.props;
        const successPercent = getSuccessPercent(this.props);
        if (!showInfo) {
            return null;
        }
        let text;
        const textFormatter = format || (percentNumber => `${percentNumber}%`);
        const isLineType = type === 'line';
        if (format || (progressStatus !== 'exception' && progressStatus !== 'success')) {
            text = textFormatter(validProgress(percent), validProgress(successPercent));
        }
        else if (progressStatus === 'exception') {
            text = isLineType ? <CloseCircleFilled /> : <CloseOutlined />;
        }
        else if (progressStatus === 'success') {
            text = isLineType ? <CheckCircleFilled /> : <CheckOutlined />;
        }
        return (<span className={`${prefixCls}-text`} title={typeof text === 'string' ? text : undefined}>
        {text}
      </span>);
    }
    render() {
        return <ConfigConsumer>{this.renderProgress}</ConfigConsumer>;
    }
}
Progress.defaultProps = {
    type: 'line',
    percent: 0,
    showInfo: true,
    // null for different theme definition
    trailColor: null,
    size: 'default',
    gapDegree: undefined,
    strokeLinecap: 'round',
};
